<template>
  <!-- 头部导航 -->
  <div
    class="nav"
    :style="navBgShow?'backgroundColor: #ffffff;':'backgroundColor:#ffffff'"
  >
    <div class="nav-fixed app-position" v-if="!navBgShow">
      <div class="logo">
        <img
          src="../assets/logo@3x.db1a3a9.png"
          alt=""
        >
        <span>因为专注 所以专业</span>
      </div>
      <div class="nav-right">
        <ul class="nav-ul">
          <li
            class="nav-li-item"
            v-for="(item,index) in navList"
            :key="index"
            @click="go(item,index)"
            @mouseenter="hoverActiveIndex = index"
            @mouseleave="hoverActiveIndex = ''"
          >
            <div
              :class="{active_nav:activeIndex == item.path,active_hover_nav:hoverActiveIndex === index}"
              class="nav_title"
            >{{item.title}}</div>
            <div
              :class="{hover_nav_roate:hoverActiveIndex === index,hover_nav_roate_f:hoverActiveIndex !== index}"
              class="nav_icon"
              v-if="item.children > 0"
            >
              <i class="iconfont icon-xiangyoujiantou"></i>
            </div>
            <div
              v-show="hoverActiveIndex === index"
              class="nav_children"
              v-if="item.children > 0"
            >
            </div>
          </li>
        </ul>
      </div>
      <div class="nav-app-close" @click="Appclose = !Appclose">
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div class="nav-right-app" :style="{
        display: Appclose ? 'block': 'none'
      }">
        <ul class="nav-ul">
          <li
            class="nav-li-item"
            v-for="(item,index) in navList"
            :key="index"
            @click="go(item,index)"
          >
            <div
              :class="{active_nav:activeIndex == item.path,active_hover_nav:hoverActiveIndex === index}"
              class="nav_title"
            >{{item.title}}</div>
            <div
              :class="{hover_nav_roate:hoverActiveIndex === index,hover_nav_roate_f:hoverActiveIndex !== index}"
              class="nav_icon"
              v-if="item.children > 0"
            >
              <i class="iconfont icon-xiangyoujiantou"></i>
            </div>
            <div
              v-show="hoverActiveIndex === index"
              class="nav_children"
              v-if="item.children > 0"
            >
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="bk_fixed">
      <img src="@/assets/bg.png" alt=""/>
    </div>
    <el-backtop></el-backtop>
  </div>
</template>
<script>
export default {
  data() {
    return {
      activeIndex: "/", //头部导航
      hoverActiveIndex: "", // 鼠标悬浮
      Appclose: false, // 是否显示
      navList: [
        {
          title: "首页",
          path: "/",
          children: [],
          bkImg: "",
        },
        {
          title: "原型设计",
          path: "/Prototypedesign",
          children: [],
          bkImg: "",
        },
        {
          title: "用户案例",
          path: "/userDemo",
          children: [],
          bkImg: "",
        },
        {
          title: "技术介绍",
          path: "/technology",
          children: [],
          bkImg: "",
        },
        {
          title: "联系我们",
          path: "/about",
          children: [],
          bkImg: "",
        },
        // {
        //   title: "申请试用",
        //   path: "",
        //   children: [],
        //   bkImg: "",
        //   url: 'https://zqkf.gcjlkj.com/index/index?code=tYM2nQhcy3C8ELYdy3FkUkSNN1sLpcZ5jKi4MYA3XOG1NLxUpNmNLWcmz12qHQoQlj2s4iXw%2FzjgvtmomDR0VshLNZtBAxBLxRoBsOnYh8%2FS7vKKZuG9lF1RoudmuJT4XQ'
        // },
        {
          title: "真琦案例",
          path: "",
          children: [],
          bkImg: "",
          url: "http://47.99.87.26:3000/"
        },
        {
          title: "登录",
          path: "",
          children: [],
          bkImg: "",
          url: "https://www.gcjlkj.cn:8700"
        },
      ],
      navBgShow: false,
    };
  },
  watch:{
      '$route':{
          handler(newVal,oldVal){
             this.activeIndex = newVal.path
          },
          deep: true, // 深度观察监听 设置为 true
          immediate: true, // 第一次初始化渲染就可以监听到
      }
  },
  mounted() {
    // this.activeIndex = this.$router.path
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll(val) {
      let scrollTop =
        document.documentElement && document.documentElement.scrollTop;
      if (scrollTop > 50) {
        this.navBgShow = true;
      } else {
        this.navBgShow = false;
      }
    },
    go(item){
      if(item.url){
        window.open(item.url)
      }else{
        this.$router.push(item.path)
      }
    }
  },
};
</script>
<style scoped lang="less">
.nav {
  position: fixed;
  width: 100%;
  height: auto;
  z-index: 970;
  .nav-fixed {
    width: 1300px;
    position: relative;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 80px;
    .logo {
      width: 280px;
      height: 80px;
      display: flex;
      flex-direction: row;
      align-items: center;
      img {
        width: 130px;
        height: auto;
      }
      span {
        margin-left: 18px;
        font-size: 14px;
      }
    }
    .nav-right {
      // width: 100%;
      flex: 1;
      display: flex;
      // flex-direction: row;
      justify-content: flex-end;
      .nav-ul {
        display: flex;
        align-items: center;
        .nav-li-item {
          position: relative;
          padding: 10px 30px;
          cursor: pointer;
          .nav_icon {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
          }
          .hover_nav_roate {
            animation: nav_roate_line 0.1s linear;
            animation-fill-mode: forwards;
          }
          .hover_nav_roate_f {
            animation: roate_line_f 0.1s linear;
            animation-fill-mode: forwards;
          }
          .iconfont {
            font-size: 12px;
          }
        }
      }
    }
    .use {
      /deep/.el-button {
        font-size: 14px;
        height: 32px;
        display: flex;
        flex-direction: row;
        align-items: center;
        color: #fff;
        background: #eb2a38;
        border-radius: 18px;
        font-weight: 400;
        border: none;
      }
    }
  }
}
.bk_fixed{
  position: fixed;
  bottom: 0;
  left: 0;
}
// 移动端
.nav-right-app{
  position: absolute;
  background: #FFF;
  width: 100%;
  top: 100%;
  .nav-ul{
    margin: 0 !important;
    padding: 0 20px !important;
    .nav-li-item{
      text-align: center;
      padding: 10px 0;
    }
  }
}
.nav-app-close{
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  span{
    display: block;
    width: 25px;
    height: 4px;
    background: #333;
    margin-bottom: 5px;
  }
  span::last-children{
    margin: 0;
  }
}
</style>