import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/technology',
    name: 'technology',
    component: () => import(/* webpackChunkName: "about" */ '../views/technology.vue')
  },
  {
    path: '/userDemo',
    name: 'userDemo',
    component: () => import(/* webpackChunkName: "about" */ '../views/userDemo.vue')
  },
  {
    path: '/Prototypedesign',
    name: 'Prototypedesign',
    component: () => import(/* webpackChunkName: "about" */ '../views/Prototypedesign.vue')
  }
]

const router = new VueRouter({
  routes,
  mode: 'history',
  scrollBehavior(){
    return {x:0,y:0}
  }
})

export default router
