<template>
  <div class="home">
    <Header />
    <div class="positionFixed">
      <a href="#one">移动端开发</a>
      <a href="#two">软件开发</a>
      <a href="#there">物联网平台</a>
      <a href="#fore">自研系统</a>
      <a href="#five">低代码平台</a>
      <a href="#six">真琦自媒体</a>
      <!-- <a href="#serven" :class="{positionFixedactive:scrollIndex==6}">特色功能</a> -->
      <a href="#event">UI原型设计</a>
      <a href="#tens">荣誉证书</a>
      <router-link to="/userDemo">用户案例</router-link>
      <!-- <a href="" @click.native="goTousre">用户案例</a> -->
      <a href="#top">返回顶部</a>
    </div> 
    <div class="banner" id="top">
      <el-carousel>
        <el-carousel-item
          v-for="item in bannerList"
          :key="item"
        >
          <img
            :src="item"
            alt=""
            class="banner_img"
          />
        </el-carousel-item>
      </el-carousel>
    </div>
    <!-- 公司介绍 -->
    <div class="aboutUs">
      <div class="abouts_l">
        <div class="about_l_tit">真琦科技是谁</div>
        <div class="about_l_img">
          <img
            src="@/assets/zqkj.jpg"
            alt=""
          />
        </div>
      </div>
      <div class="abouts_r">
        <div class="abouts_r_txt">
          <p>杭州真琦科技有限公司，专业从事软件开发、技术咨询、技术服务人工智能等多样化的信息软件公司。近年来致力于企业信息化，移动端开发（APP，小程序，微信公众号，H5），软件开发（ERP、CRM、MES、OA、企业办公管理、科研系统、在线教育、人资管理、物流管理），物联网开发（人工智能、嵌入式开发、设备自动化控制），网站建设，自媒体一键分发平台，工作流的研发、UI原型设计，软件服务（软件部署，微信小程序注册和认证，app上架，软著申请）,公司以前沿的技术为优势、以技术创新为核心、以优质服务为支撑，致力于为企业和个人提供软件和设计一站式定制服务。</p>
          <p>现公司已为500多家企业或个人提供行业互联网运营解决方案，用户遍布制造业、服务、外贸电商、建筑与房地产、流通与物流、零售等百余个行业。连续多年获得猪八戒优秀服务商、连续十年合作服务商，浙江省优秀软件企业等多项殊誉</p>
        </div>
      </div>
    </div>
    <!-- 核心服务 -->
    <div class="special" v-if="false">
      <div class="container widths">
        <p
          class="title"
          style="margin-bottom: 70px;"
        >核心服务</p>
        <div
          class="core_ul"
          @mouseleave="corehoverIndex = 2"
        >
          <div
            class="core_li"
            :class="['core_li_item'+index,{core_li_hover:corehoverIndex==index}]"
            v-for="(item,index) in coreList"
            :key="index"
            @mouseleave="corehoverIndex = -1"
            @mouseenter="corehoverIndex = index"
          >
            <div
              class="core_li_con core_li_hover_con_F"
              :class="{core_li_hover_con:corehoverIndex==index}"
            >
              <div
                class="core_img_icon"
                v-show="corehoverIndex != index"
              >
                <img
                  :src="item.img"
                  alt=""
                >
              </div>
              <div
                class="core_img_icon_hover"
                v-show="corehoverIndex == index"
              >
                <img
                  :src="item.hover_img"
                  alt=""
                >
              </div>
              <div class="core_title">{{item.title}}</div>
              <div class="core_info">{{item.info}}</div>
              <div
                class="core_business"
                v-show="corehoverIndex != index"
              >
                <span
                  v-for="(i,indexs) in item.business.split(',')"
                  :key="indexs"
                >{{i}}</span>
              </div>
              <div
                class="core_hover_info"
                v-show="corehoverIndex == index"
              >{{item.hover_info}}</div>
              <div
                class="core_hover_btn"
                v-show="corehoverIndex == index"
              >{{item.hover_btn}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 安全、稳定的互联网+产品 -->
    <div
      class="special"
      style="background:#f1f4fd;"
      v-if="false"
    >
      <div class="container widths">
        <p style="text-align:center;color:#3e396b;font-size:30px;margin-bottom: 15px;">安全、稳定的互联网+产品</p>
        <p style="text-align:center;color:#3e396b;font-size:18px;margin-bottom: 5px;margin-top:0;">8年从业经验，8年专注互联网，为多个政府部门、集团公司以及各类商业机构插上互联网的翅膀</p>
        <p style="text-align:center;color:#3e396b;font-size:18px;margin-bottom: 30px;margin-top:0;">CMS系统融入互联网营销思维、提供营销工具、数据统计、客户管理等丰富应用功能，让互联网转型轻而易举</p>
        <div class="safe">
          <div class="safe_l">
            <div class="safe_ul">
              <div
                class="safe_li"
                :class="{safe_li_hover:safeIndex == index}"
                v-for="(item,index) in safeList"
                :key="index"
                @mouseenter="safeIndex = index"
              >
                <div class="safe_li_l">
                  <img
                    :src="item.icon"
                    alt=""
                    v-show="safeIndex != index"
                  />
                  <img
                    :src="item.hover_icon"
                    alt=""
                    v-show="safeIndex == index"
                  />
                </div>
                <div class="safe_li_r">
                  <div class="safe_li_tit">{{item.title}}</div>
                  <div class="safe_li_info">{{item.info}}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="safe_r">
            <div
              class="safe_img"
              :class="{safe_img_hover: safeIndex == index}"
              v-for="(item,index) in 3"
              :key="index"
              :style="{Zindex:safeIndex}"
            >
              <img
                :src="require('@/assets/'+index+'.png')"
                alt=""
                v-show="safeIndex == index"
              />
            </div>
          </div>
        </div>
      </div>

    </div>
    <!-- 公司服务 -->
    <div class="special">
      <div class="container widths">
        <p class="title">经营类目</p>
        <div class="fw_ul">
          <div
            class="fw_li"
            :class="{fw_li_cative:serveIndex === index}"
            v-for="(item,index) in serviceList"
            :key="index"
            @mouseleave="serveIndex = ''"
            @mouseenter="serveIndex = index"
          >
            <div
              class="fw_icon"
              :class="{
              fw_icon_active_f: false,
              fw_icon_active: serveIndex === index,
            }"
            >
              <i
                class="iconfont"
                :class="item.icon"
              ></i>
            </div>
            <div class="fw_title">{{item.name}}</div>
            <div class="fw_info">{{item.info}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="special">
      <p class="title">案例展示</p>
      <img src="@/assets/home/bk_01.png" id="one" alt="" style="width: 100%;display:block;"/>
      <img src="@/assets/home/bk_02.png" alt="" style="width: 100%;display:block;"/>
      <img src="@/assets/home/bk_03.png" alt="" style="width: 100%;display:block;"/>
      <img src="@/assets/home/bk_04.png" id="two" alt="" style="width: 100%;display:block;"/>
      <img src="@/assets/home/bk_05.png" alt="" style="width: 100%;display:block;"/>
      <img src="@/assets/home/bk_06.png" alt="" style="width: 100%;display:block;"/>
      <img src="@/assets/home/bk_07.png" alt="" style="width: 100%;display:block;"/>
      <img src="@/assets/home/bk_08.png" id="there" alt="" style="width: 100%;display:block;"/>
      <img src="@/assets/home/bk_09.png" alt="" style="width: 100%;display:block;"/>
      <!-- <img src="@/assets/home/bk_10.png" alt="" style="width: 100%;display:block;"/> -->
      <!-- <img src="@/assets/home/bk_11.png" alt="" style="width: 100%;display:block;"/> -->
      <img src="@/assets/home/bk_12.png" id="fore" alt="" style="width: 100%;display:block;"/>
    </div>
    <!-- 业务流程 -->
    <!-- <div class="special">
      <div class="container widths">
        <p class="title">业务流程</p>
        <div class="flow_ul">
          <div
            class="flow_li"
            :class="{active_flow_li:index === activeFlowIndex}"
            v-for="(item,index) in flowList"
            :key="index"
            :style="index == 4 ? 'position:relative;':''"
          >
            <div>
              <div class="flow_icon">
                <i
                  class="iconfont"
                  :class="item.icon"
                ></i>
              </div>
              <div class="flow_name">{{item.name}}</div>
            </div>
            <div
              class="flow_icon_l"
              v-if="index > 4 && index < 9"
            >
              <i class="iconfont icon-jiantou_yemian_xiangzuo"></i>
            </div>
            <div
              class="flow_icon_r"
              v-if="index < 4"
            >
              <i class="iconfont icon-jiantou_yemian_xiangyou"></i>
            </div>
            <div
              class="flow_icon_c"
              v-if="index == 4"
            >
              <i class="iconfont icon-jiantou_yemian_xiangyou"></i>
            </div>
            <div
              class="flow_icon_r"
              v-if="index == 4 || index == 9"
            ></div>
          </div>
        </div>
      </div>
    </div> -->
    <!-- 八大功能，让开发简单又高效 -->
    <div class="special" id="five">
      <p class="title">八大功能，让开发简单又高效</p>
      <div class="simple widths">
        <div class="simple_ul">
          <div class="simple_li" v-for="(item,index) in simpleList" :key="index" @mouseenter="simpleIndex = index">
            <div class="simple_con" :class="{acsimple_con: simpleIndex == index}">
              <div class="simple_title">{{item.title}}</div>
              <div class="simple_info">{{item.info}}</div>
            </div>
          </div>
        </div>
        <div class="simple_img">
          <img :src="require('@/assets/home/f_'+ (simpleIndex + 1) +'.png')" alt="">
        </div>
      </div>
    </div>
    <!-- 多平台 -->
    <div class="platform-list relative" id="six">
      <div class="widths">
        <img
          width="187"
          src="../assets/home/元素2@3x.425cedd.png"
          class="absolute"
          style="left: 0;bottom: 0px;"
          alt=""
        >
        <div class="list">
          <p class="title">多平台发布，多账号管理</p>
          <div class="fspan">
            真琦自媒体工具支持40+自媒体账号一键发布，60+账号管理，可同时添加500+个账号
          </div>
          <div class="ant-row">
            <el-row :gutter="12">
              <el-col
                :span="2"
                v-for="(item,index) in icons"
                :key="index"
              >
                <div class="grid-content">
                  <img
                    :src="item.icon"
                    alt=""
                    width="42"
                    height="42"
                  >
                  <span style="color: rgb(140, 140, 140);font-size: 12px;">{{item.name}}</span>
                </div>
              </el-col>
            </el-row>
            <div style="color: #676767;text-align: center;" v-if="false">持续更新中...</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 蚁小二八大特色功能 -->
    <div class="special" style="padding-bottom: 0;" id="serven">
      <div class="container widths">
        <p class="title">真琦科技特色功能</p>
        <div class="list">

          <div class="grids">
            <div
              class="grid-content"
              v-for="(item,index) in feature"
              :key="index"
            >
              <div class="item">
                <img
                  :src="item.icon"
                  alt=""
                  width="32"
                >
                <p class="mt">{{item.name}}</p>
                <div
                  class="f15"
                  style="color: rgba(38, 38, 38, 0.85)"
                >
                  <span style="text-align: justify ">{{item.content}}</span>
                </div>
              </div>
            </div>

          </div>

        </div>
      </div>
       <!-- <img src="@/assets/home/zm_02.png" alt="" style="width: 100%;display:block;"/> -->
    </div>
    <div class="special" id="event">
      <img src="@/assets/home/bk_13.png" alt="" style="width: 100%;display:block;"/>
      <img src="@/assets/home/bk_14.png" alt="" style="width: 100%;display:block;"/>
      <!-- <img src="@/assets/home/bk_15.png" alt="" style="width: 100%;display:block;"/> -->
    </div>
    <!-- 营业执照 -->
    <div class="special" id="tens">
      <p class="title">荣誉证书</p>
      <div class="businessLicense">
        <div class="businessLicenseItem" @click="openUrl('http://www.gcjlkj.com/state/01.jpg')">
          <img src="../assets/01.png" alt="">
        </div>
        <div class="businessLicenseItem" @click="openUrl('http://www.gcjlkj.com/state/02.jpg')">
          <img src="../assets/02.png" alt="">
        </div>
        <div class="businessLicenseItem" @click="openUrl('http://www.gcjlkj.com/state/03.jpg')">
          <img src="../assets/03.png" alt="">
        </div>
        <div class="businessLicenseItem" @click="openUrl('http://www.gcjlkj.com/state/04.jpg')">
          <img src="../assets/04.png" alt="">
        </div>
      </div>
      
    </div> 
    <!-- 合作服务 -->
    <div class="open-platform" id="nice">
      <p class="title">真琦服务</p>
      <img src="@/assets/home/yw_01.png" alt="" style="width: 100%;display:block;"/>
      <img src="@/assets/home/bk_16.png" alt="" style="width: 100%;display:block;"/>
      <img src="@/assets/home/bk_17.png" alt="" style="width: 100%;display:block;" id="bk_17_pc"/>
      <img src="@/assets/home/bk_17_app.png" alt="" style="width: 100%;display:block;" id="bk_17_app"/>
    </div>
    
    <!-- 他们都在用 -->
    <div class="are-using">
      <div class="container widths">
        <p class="title">他们都在用真琦</p>
      </div>
      <div class="swiper-container">
        <div class="scroll-block">
          <div class="scroll-item">
            <img
              src="../assets/logo-min.bfc774a.png"
              alt=""
            >
            <img
              src="../assets/logo-min.bfc774a.png"
              alt=""
            >
          </div>
        </div>
      </div>
    </div>
    
    <Footer></Footer>
  </div>
</template>

<script>
import myTouchNav from "@/components/myTouchNav.vue";
import priceList from "@/components/priceList.vue";
export default {
  name: "Home",
  components: {
    myTouchNav,
    priceList
  },
  data() {
    return {
      serviceList: [
        {
          icon: "icon-shoujidiannao",
          name: "APP移动端开发",
          info: "电商类,跑腿类,社交类,教育类,直播类,招聘类,房产类,物业类,旅游类,家政类,同城类,医疗类,音乐类,任务类,区块链,数字藏品,淘客类,货运类,居家类",
        },
        {
          icon: "icon-xiaochengxu",
          name: "小程序",
          info: "微信小程序,支付宝小程序,百度小程序,字节跳动小程序,QQ小程序,360小程序,快手小程序,飞书小程序,钉钉小程序,京东小程序",
        },
        {
          icon: "icon-xitongguanlihoutai",
          name: "软件开发",
          info: "ERP进销存系统,OA协同办公,CRM管理系统,MES系统,SCRM,科研平台,教务系统,营销系统,分销系统,教育,财务,企业管理,实验室管理系统",
        },
        {
          icon: "icon-wulianwang",
          name: "物联网",
          info: "Android系统，Windows应用程序,嵌入式开发,PCB、PCBA、其他电路设计,单片机开发,人工智能,设备自动化控制",
        },
        {
          icon: "icon-xitongguanlihoutai",
          name: "网站建设",
          info: "自适应网站,企业网站,微信公众号,H5开发,定制开发,网站二次开发,网站维护/安全,域名服务器",
        },
        {
          icon: "icon-lingshoubaobiao",
          name: "自研平台",
          // info: "自定义表单,拖拽式操作,可视化应用,灵活构建管理系统,快速部署工作流,引擎支持一站式开发,多端使用,需求变更只调整业务流程或修改操作即可",
          // info: "真琦自媒体历时5年开发运营.支持1000+账号管理.3分钟完成2小时分发工作,更稳定的一站式管理、营销、发布工具    换成 真琦自媒体分发平台，多账号管理，多文章视频一键发布、营销工具"
          info: "自定义表单,工作流引擎,可视化大屏,报表设计，真琦自媒体分发平台,多账号管理,多文章视频一键发布、营销工具"
        },
        {
          icon: "icon-zaixianjiaoyu",
          name: "UI原型设计",
          info: "主图/banner设计,网站、PC、APP、小程序、智能终端、游戏界面UI设计,UI原型设计",
        },
        {
          icon: "icon-tuandui",
          name: "软件服务",
          info: "API开发/集成,数据开发计算,网络部署,安全防护,软件测试,环境搭建,故障排查,软件免费部署,小程序注册和认证,app上架,软著申请",
        },
      ],
      serveIndex: "",
      simpleList: [
        {
          title: '自定义表单',
          info: '无需代码，拖拽式操作，自动生成可视化应用 即可灵活的构建业务管理系统，加快业务部署'
        },{
          title: '工作流引擎',
          info: '支持一站式开发、多端使用，需求发生变化只 需调整业务服务流程或修改操作即可'
        },{
          title: '移动端、APP、小程序',
          info: '将产品开发中重复繁琐的步骤抽离出来， 让开发更简单、更快速，满足个性化需求'
        },{
          title: '可视化大屏',
          info: '自定义多维度来看企业管理经营数据。让管理者解企业⼈、财、物信息动向，从而进⾏决策'
        },{
          title: '报表设计',
          info: '通过视图表单模式，像做表单样实现基础的报 表功能，且还可灵活设定显示列及筛选条件等'
        },{
          title: '权限管理',
          info: '独立的权限管理体系，多套系统可以进行统一 管理权限；注重权限安全，拒绝一切非法访问'
        },{
          title: '代码生成器',
          info: '将产品开发中重复繁琐的步骤抽离出来，让开发更简单、更快速，满足个性化需求'
        },{
          title: '多语言选择',
          info: '将产品开发中重复繁琐的步骤抽离出 来，让开发简单、快速，满足个性化需求'
        }
      ],
      simpleIndex: 0,
      simpleTime: null,
      flowList: [
        {
          icon: "icon-zaixiangoutong",
          name: "项目沟通",
        },
        {
          icon: "icon-fenxi",
          name: "需求分析",
        },
        {
          icon: "icon-cz-ldpg",
          name: "专业评估",
        },
        {
          icon: "icon-yaoqing-neirong",
          name: "方案设计",
        },
        {
          icon: "icon-kuaisubaojia",
          name: "项目报价",
        },
        {
          icon: "icon-shouhou",
          name: "售后服务",
        },
        {
          icon: "icon-sousuoliebiao",
          name: "项目验收",
        },
        {
          icon: "icon-tiaoshi",
          name: "项目调试",
        },
        {
          icon: "icon-sheji",
          name: "开始设计",
        },
        {
          icon: "icon-hetong",
          name: "签订合同",
        },
      ],
      activeFlowIndex: false, //
      navList: [
        {
          title: "首页",
          path: "/",
          children: [],
          bkImg: "",
        },
        {
          title: "技术介绍",
          path: "/",
          children: [],
          bkImg: "",
        },
        {
          title: "用户案例",
          path: "/",
          children: [],
          bkImg: "",
        },
        {
          title: "会员服务",
          path: "/",
          children: [],
          bkImg: "",
        },
        {
          title: "关于我们",
          path: "/",
          children: [],
          bkImg: "",
        },
      ],
      corehoverIndex: 2,
      coreList: [
        {
          img: require("@/assets/app.png"),
          hover_img: require("@/assets/app_hover.png"),
          title: "APP 开发",
          info: "为您提供可靠且专业的App开发",
          hover_info:
            "基于微信为企业提供公众号开发，帮助企业实现O2O线上线下互通，SCRM社会化客户关系管理，移动电商，小程序，微场景等多个层面的业务开发。",
          hover_btn: "功能特点：利于场景化营销，海量业务功能组件，便于分享传递",
          business: "安卓APP开发,苹果APP开发",
        },
        {
          img: require("@/assets/wechat.png"),
          hover_img: require("@/assets/wechat_hover.png"),
          title: "微信/小程序开发",
          info: "为您提供可靠且专业的微信/小程序",
          hover_info:
            "基于微信为企业提供公众号开发/小程序开发，帮助企业实现O2O线上线下互通，SCRM社会化客户关系管理，移动电商，小程序，微场景等多个层面的业务开发。",
          hover_btn:
            "功能特点：利于场景化营销，海量业务功能组件，便于分享传递。",
          business: "微信定制开发,微官网建设,小程序开发,H5应用开发",
        },
        {
          img: require("@/assets/shop.png"),
          hover_img: require("@/assets/shop_hover.png"),
          title: "网站建设",
          info: "聚焦企业网络形象，轻松转型互联网+",
          hover_info:
            "多终端网站建设（PC+手机+微信），满足各类企业、集团、政府、院校对品牌官网、营销网站宣传的需求。 功能特点：一核心多终端 更好的用户体验 独立开发安全部署。",
          hover_btn: "功能特点：多终端 电脑、手机、小程序、微信 数据同步",
          business:
            "B2B2C商城建设,B2C商城建设,O2O商城建设,营销型网站建设,企业官网建设",
        },
        {
          img: require("@/assets/gov.png"),
          hover_img: require("@/assets/gov_hover.png"),
          title: "高端定制开发",
          info: "根据您的需求，策划量身定制",
          hover_info:
            "依托核心技术团队近十年来网络平台设计开发经验积累，深度理解客户实际业务需求，前瞻技术及产业发展未来，为客户量身定制PC网站、移动端网站、微信公众平台建设，实现多终端、跨平台、智能化的Web方案，通过视觉设计、交互设计、功能开发，完整无缝呈现功能需求与服务创新。",
          hover_btn:
            "功能特点：业务流程量身定制 交互体验方式创新 创新转型全程咨询。",
          business: "网站开发,微信公众号开发,微信小程序开发,App开发",
        },
        {
          img: require("@/assets/website.png"),
          hover_img: require("@/assets/website_hover.png"),
          title: "ERPOA定制开发",
          info: "聚焦企业品牌形象",
          hover_info:
            "扩大满足企业网络形象宣传展示需求，从企业形象、信息宣传、营销支持、客户感知、受理咨询等多方面考虑，让网站变成您的网络门迎！",
          hover_btn: "功能特点：助力传播推广 用户访问分析 云平台安全运行。",
          business: "ERP,OA,CRM,FM,管理系统",
        },
      ],
      safeIndex: 0,
      safeList: [
        {
          icon: require("../assets/yiyuan_home6.png"),
          hover_icon: require("../assets/yiyuan_home6_active.png"),
          title: "企业营销系统",
          info: "Enterprise Marketing System",
        },
        {
          icon: require("../assets/yiyuan_home9.png"),
          hover_icon: require("../assets/yiyuan_home9_active.png"),
          title: "电商系统",
          info: "Electrical Bus iness System",
        },
        {
          icon: require("../assets/yiyuan_home11.png"),
          hover_icon: require("../assets/yiyuan_home11_active.png"),
          title: "微信平台",
          info: "Micro-letter Platform",
        },
      ],
      bannerList: [
        require("../assets/home/banner1.png"),
        require("../assets/home/banner2.png"),
        require("../assets/home/banner3.png"),
        require("../assets/home/banner4.png"),
        require("../assets/home/banner5.png"),
        require("../assets/home/banner6.png"),
        require("../assets/home/banner7.png"),
        require("../assets/home/banner8.png"),
      ],
      activeName: "first", //页面导航
      navBgShow: false,
      icons: [
        {
          icon: require("../assets/home/icon/1.png"),
          name: "头条号",
        },
        {
          icon: require("../assets/home/icon/2.png"),
          name: "百家号",
        },
        {
          icon: require("../assets/home/icon/3.png"),
          name: "企鹅号",
        },
        {
          icon: require("../assets/home/icon/4.png"),
          name: "一点号",
        },
        {
          icon: require("../assets/home/icon/5.png"),
          name: "搜狐号",
        },
        {
          icon: require("../assets/home/icon/6.png"),
          name: "大鱼号",
        },
        {
          icon: require("../assets/home/icon/7.png"),
          name: "网易号",
        },
        {
          icon: require("../assets/home/icon/8.png"),
          name: "趣头条",
        },
        {
          icon: require("../assets/home/icon/9.png"),
          name: "东方号",
        },
        {
          icon: require("../assets/home/icon/10.png"),
          name: "新浪看点",
        },
        {
          icon: require("../assets/home/icon/11.png"),
          name: "快传号",
        },
        {
          icon: require("../assets/home/icon/12.png"),
          name: "大风号",
        },
        {
          icon: require("../assets/home/icon/13.png"),
          name: "爱奇艺号",
        },
        {
          icon: require("../assets/home/icon/14.png"),
          name: "秒拍",
        },
        {
          icon: require("../assets/home/icon/15.png"),
          name: "哔哩哔哩",
        },
        {
          icon: require("../assets/home/icon/16.png"),
          name: "优酷",
        },
        {
          icon: require("../assets/home/icon/17.png"),
          name: "搜狐视频",
        },
        {
          icon: require("../assets/home/icon/18.png"),
          name: "惠头条",
        },
        {
          icon: require("../assets/home/icon/19.png"),
          name: "抖音",
        },
        {
          icon: require("../assets/home/icon/20.png"),
          name: "微信公众号",
        },
        {
          icon: require("../assets/home/icon/21.png"),
          name: "新浪微博",
        },
        {
          icon: require("../assets/home/icon/23.png"),
          name: "简书号",
        },
        {
          icon: require("../assets/home/icon/24.png"),
          name: "快手",
        },
        {
          icon: require("../assets/home/icon/26.png"),
          name: "腾讯视频",
        },
        {
          icon: require("../assets/home/icon/27.png"),
          name: "美拍",
        },
        {
          icon: require("../assets/home/icon/29.png"),
          name: "知乎",
        },
        {
          icon: require("../assets/home/icon/30.png"),
          name: "腾讯微视",
        },
        {
          icon: require("../assets/home/icon/31.png"),
          name: "小红书",
        },
        {
          icon: require("../assets/home/icon/32.png"),
          name: "微信视频号",
        },
        {
          icon: require("../assets/home/icon/33.png"),
          name: "蜂网",
        },
        {
          icon: require("../assets/home/icon/34.png"),
          name: "芒果TV",
        },
        {
          icon: require("../assets/home/icon/35.png"),
          name: "喜马拉雅",
        },
        {
          icon: require("../assets/home/icon/36.png"),
          name: "虎牙视频",
        },
        {
          icon: require("../assets/home/icon/37.png"),
          name: "快看点",
        },

        {
          icon: require("../assets/home/icon/39.png"),
          name: "美柚",
        },
        {
          icon: require("../assets/home/icon/40.png"),
          name: "皮皮虾",
        },
        {
          icon: require("../assets/home/icon/41.png"),
          name: "全民小视频",
        },
        {
          icon: require("../assets/home/icon/42.png"),
          name: "wifi万能钥匙",
        },
        {
          icon: require("../assets/home/icon/43.png"),
          name: "爆米花视频号",
        },
        {
          icon: require("../assets/home/icon/45.png"),
          name: "宝宝知道",
        },
        {
          icon: require("../assets/home/icon/46.png"),
          name: "百度知道",
        },
        {
          icon: require("../assets/home/icon/47.png"),
          name: "宝树号",
        },
        {
          icon: require("../assets/home/icon/52.png"),
          name: "太平洋号",
        },
        {
          icon: require("../assets/home/icon/53.png"),
          name: "易车号",
        },
        {
          icon: require("../assets/home/icon/54.png"),
          name: "汽车头条",
        },
        {
          icon: require("../assets/home/icon/55.png"),
          name: "豆瓣",
        },
        {
          icon: require("../assets/home/icon/59.png"),
          name: "车家号",
        },
        {
          icon: require("../assets/home/icon/60.png"),
          name: "斗鱼",
        },
        {
          icon: require("../assets/home/icon/72.png"),
          name: "AcFun",
        },
        {
          icon: require("../assets/home/icon/74.png"),
          name: "雪球号",
        },
        {
          icon: require("../assets/home/icon/77.png"),
          name: "财富号",
        },
        {
          icon: require("../assets/home/icon/79.png"),
          name: "黑猫投诉",
        },
        {
          icon: require("../assets/home/icon/80.png"),
          name: "腾讯内容开放平台",
        },
        {
          icon: require("../assets/home/icon/81.png"),
          name: "老司机",
        },
        {
          icon: require("../assets/home/icon/90.png"),
          name: "西瓜视频",
        },
        {
          icon: require("../assets/home/icon/91.png"),
          name: "多多视频",
        },
        {
          icon: require("../assets/home/icon/211.png"),
          name: "微博头条文章",
        },
      ],
      feature: [
        {
          icon: require("../assets/home/icon/1.be4d434.png"),
          name: "一键发布",
          content:
            "60+自媒体平台图文、视频发布参数一键配置，轻松实现3分钟一键发布",
        },
        {
          icon: require("../assets/home/icon/2.9073ab0.png"),
          name: "账号管理",
          content: "500+账号管理，根据领域、分组、类型账号实时切换、方便管理",
        },
        {
          icon: require("../assets/home/icon/3.62166c2.png"),
          name: "微头条/动态备份",
          content:
            "支持动态一键发布至微头条、微博、百家号动态、知乎想法，一点号短内容。",
        },
        {
          icon: require("../assets/home/icon/4.1122c69.png"),
          name: "数据统计",
          content:
            "支持按内容、部门员工、平台、账号多个维度数据进行，筛选查询，数据更新延时不超过4小时",
        },
        {
          icon: require("../assets/home/icon/5.7897972.png"),
          name: "收益统计",
          content: "统计发文收益，随时反馈数据（支持屏蔽成员收益）",
        },
        {
          icon: require("../assets/home/icon/6.270de07.png"),
          name: "原创质量检测",
          content:
            "检测文章原创度、文本纠错、标题、图片、违禁词、错别字、政治敏感等多元化检测项目，让你的文章更安全，内容有价值",
        },
        {
          icon: require("../assets/home/icon/8.b456933.png"),
          name: "智能创作",
          content: "在线搜图、标题助手、热点发现，为您的创作赋能增彩",
        },
        {
          icon: require("../assets/home/icon/9.cf85b5f.png"),
          name: "权限设置",
          content:
            "支持自定义岗位权益设置，支持二级管理权限，独有的内容审核发稿权限",
        },
      ],
      miniList: [
        {
          icon: require("../assets/home/icon/OEM系统定制.09fd51a.png"),
          name: "OEM系统定制",
          tags: ["按期交付", "售后服务"],
          content: {
            icon: require("../assets/home/icon/OEM系统定制选中.192dc65.png"),
            name: "OEM系统定制",
            content:
              "为党政机关、媒体、企业及开发者等提供“全面、实时、准确”的新媒体内容大数据定制服务；可根据您的需求，提供蚁小二全平台功能需求定制、云分发服务",
            tags: ["按期交付", "售后服务"],
            href: "#",
          },
          miniShow: true,
        },
        {
          icon: require("../assets/home/icon/代分发服务.23f9022.png"),
          name: "代分发业务",
          content: {
            icon: require("../assets/home/icon/代分发服务选中.65a1ad5.png"),
            name: "代分发业务",
            content:
              "为用户提供专业的图文/视频代分发服务，帮助解决多平台多账号发布耗时的难题",
            tags: ["视频剪辑", "文章代分发", "视频代分发", "公众号代运营"],
            href: "#",
          },
          tags: ["视频剪辑", "文章代发", "视频代发", "公众号代运营"],
          miniShow: false,
        },
        {
          icon: require("../assets/home/icon/需求定制开发.72a83d3.png"),
          name: "需求定制开发",
          content: {
            icon: require("../assets/home/icon/需求定制开发选中.00b968c.png"),
            name: "需求定制开发",
            content: "可根据您的需求，提供全平台功能需求定制",
            tags: ["专业", "高效", "灵活定制"],
            href: "#",
            miniShow: false,
          },
          tags: ["专业", "高效", "灵活定制"],
          miniShow: false,
        },
      ],
      priceList: [
        {
          title: "免费版", //标题
          fu: "适合个人/工作室", //副标题
          bags: 0, //是否添加背景
          price: "0", //价格
          pricehref: "", //购买地址
          teamNum: 1, //团队人数
          accountNum: 5, //账号管理
          labelNum: 5, //账号中心打开标签数
          earnings: false, //屏蔽账号收益
          issueSpeed: 3, //分发速度-图文
          issueImg: "5篇/天", //图文/视频分发
          issueTask: 3, //多任务一键发布
          issueDraft: true, //同步至平台草稿箱
          issueTitle: false, //标题助手
          issueHotspot: false, //热点发现
          issueOnLine: false, //在线搜图
          issueDetection: "无限制", //质量检测
          issueGrouping: true, //账号分组
          issueTheHeavy: true, //防重复发布机制
          issueLabel: true, //各平台独立标签
          issueTitles: true, //各平台独立标题
          issueTiming: true, //定时发布
          issueOriginal: true, //声明原创/首发
          issueRecordImg: true, //记录图文原创
          issueRecordvideo: true, //记录视频原创
          dataPlatform: false, //平台数据
          dataStaff: false, //员工数据
          dataAccount: false, //账号数据
          dataContent: false, //内容统计
          satisfy: false, //需求优先满足
        },
        {
          title: "分发版(月付)", //标题
          fu: "适合个人/工作室", //副标题
          bags: "1", //是否添加背景
          price: "48", //价格
          pricehref: "", //购买地址
          teamNum: 2, //团队人数
          accountNum: 20, //账号管理
          labelNum: 8, //账号中心打开标签数
          earnings: true, //屏蔽账号收益
          issueSpeed: 10, //分发速度-图文
          issueImg: "无限制", //图文/视频分发
          issueTask: 10, //多任务一键发布
          issueDraft: true, //同步至平台草稿箱
          issueTitle: true, //标题助手
          issueHotspot: true, //热点发现
          issueOnLine: true, //在线搜图
          issueDetection: "无限制", //质量检测
          issueGrouping: true, //账号分组
          issueTheHeavy: true, //防重复发布机制
          issueLabel: true, //各平台独立标签
          issueTitles: true, //各平台独立标题
          issueTiming: true, //定时发布
          issueOriginal: true, //声明原创/首发
          issueRecordImg: true, //记录图文原创
          issueRecordvideo: true, //记录视频原创
          dataPlatform: true, //平台数据
          dataStaff: true, //员工数据
          dataAccount: true, //账号数据
          dataContent: true, //内容统计
          satisfy: true, //需求优先满足
        },
        {
          title: "分发版(年付)", //标题
          fu: "适合个人/工作室", //副标题
          fus: "576/年 优惠278元",
          bags: "2", //是否添加背景
          price: "298", //价格
          pricehref: "", //购买地址
          teamNum: 5, //团队人数
          accountNum: 50, //账号管理
          labelNum: 8, //账号中心打开标签数
          earnings: true, //屏蔽账号收益
          issueSpeed: 10, //分发速度-图文
          issueImg: "无限制", //图文/视频分发
          issueTask: 10, //多任务一键发布
          issueDraft: true, //同步至平台草稿箱
          issueTitle: true, //标题助手
          issueHotspot: true, //热点发现
          issueOnLine: true, //在线搜图
          issueDetection: "无限制", //质量检测
          issueGrouping: true, //账号分组
          issueTheHeavy: true, //防重复发布机制
          issueLabel: true, //各平台独立标签
          issueTitles: true, //各平台独立标题
          issueTiming: true, //定时发布
          issueOriginal: true, //声明原创/首发
          issueRecordImg: true, //记录图文原创
          issueRecordvideo: true, //记录视频原创
          dataPlatform: true, //平台数据
          dataStaff: true, //员工数据
          dataAccount: true, //账号数据
          dataContent: true, //内容统计
          satisfy: true, //需求优先满足
        },
      ],
      scrollIndex: 0,
    };
  },
  mounted() {
    setTimeout(()=>{
      let one = document.querySelector('#one').offsetTop;
      let two = document.querySelector('#two').offsetTop;
      let there = document.querySelector('#there').offsetTop;
      let fore = document.querySelector('#fore').offsetTop;
      let five = document.querySelector('#five').offsetTop;
      let six = document.querySelector('#six').offsetTop;
      let serven = document.querySelector('#serven').offsetTop;
      let event = document.querySelector('#event').offsetTop;
      let nice = document.querySelector('#nice').offsetTop;
      window.addEventListener("scroll", (e)=>{
        if(document.documentElement.scrollTop > nice){
          this.scrollIndex = 8
        }else if(document.documentElement.scrollTop+20 > event){
          this.scrollIndex = 7
        }else if(document.documentElement.scrollTop+20 > serven){
          this.scrollIndex = 6
        }else if(document.documentElement.scrollTop+20 > six){
          this.scrollIndex = 5
        }else if(document.documentElement.scrollTop+20 > five){
          this.scrollIndex = 4
        }else if(document.documentElement.scrollTop+20 > fore){
          this.scrollIndex = 3
        }else if(document.documentElement.scrollTop+20 > there){
          this.scrollIndex = 2
        }else if(document.documentElement.scrollTop+20 > two){
          this.scrollIndex = 1
        }else{
          this.scrollIndex = 0
        }
      });
    },1000)
    
    
    // this.simpleTime = setInterval(()=>{
    //   if(this.simpleIndex == 9){
    //     this.simpleIndex = 0
    //   }
    //   this.simpleIndex++
    // },3000)
  },
  destroyed(){
    clearInterval(this.simpleTime)
  },
  methods: {
    openUrl(url){
      window.open(url)
    },
    goTousre(url = '/userDemo'){
      this.$router.push(url)
    }
  },
};
</script>

<style lang="less" scoped>
@import url("../style/Home.less");
.positionFixedactive{
  color: rgb(237, 37, 52)!important;
}
.businessLicense{
  margin: 0 auto 10px;
  display: flex;
  align-items: center;
  .businessLicenseItem{
    width: 25%;
    padding: 0 20px;
    box-sizing: border-box;
    img{
      display: block;
      max-width: 100%;
    }
  }
}
.positionFixed{
  position: fixed;
  top: 100px;
  right: 20px;
  background: #FFF;
  z-index: 999;
  border-radius: 10px;
  padding: 10px;
  a{
    width: 110px;
    height: 40px;
    display: block;
    color: #333;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.banner {
  
  height: 700px;
  padding-top: 80px;
  .banner_img {
    width: 100%;
    height: 700px;
    object-fit: cover;
    object-position: center center;
  }
}
.aboutUs {
  width: 1300px;
  margin: 30px auto;
  display: flex;
  p {
    margin: 0 0 20px;
    font-size: 18px;
    color: #666;
    line-height: 30px;
  }
  // align-items: center;
  .abouts_l,
  .abouts_r {
    display: inline-block;
    width: 50%;
  }
  .about_l_tit {
    display: inline-block;
    position: relative;
    font-size: 32px;
    color: #333;
    cursor: pointer;
    font-weight: 700;
    margin-bottom: 30px;
  }
  .about_l_img {
    img {
      width: 90%;
      height: 275px;
      object-fit: cover;
    }
  }
  .about_l_tit::after {
    content: "";
    position: absolute;
    bottom: -10px;
    width: 50%;
    height: 2px;
    background: rgb(237, 37, 52);
    left: 0;
  }
}
.fw_ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .fw_li {
    width: 290px;
    height: 220px;
    background: #fff;
    margin-bottom: 15px;
    box-sizing: border-box;
    border-radius: 5px;
    box-shadow: 0 0 4px 5px #eee;
    padding: 15px;
    cursor: pointer;
    .fw_icon {
      display: inline-block;
      margin-bottom: 10px;
      .iconfont {
        font-size: 50px;
        color: #333;
      }
    }
    .fw_title {
      font-size: 20px;
      margin-bottom: 10px;
    }
    .fw_info {
      color: #666;
      font-size: 16px;
      line-height: 24px;
    }
  }
}
.flow_ul {
  display: flex;
  flex-wrap: wrap;
  .flow_li {
    width: 240px;
    margin-bottom: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    .flow_icon {
      border: 2px solid #333;
      border-radius: 50%;
      height: 50px;
      width: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 15px;
      .iconfont {
        font-size: 35px;
        color: #333;
      }
    }
    .flow_name {
      font-size: 18px;
    }
    .flow_icon_c {
      position: absolute;
      transform: rotate(90deg);
      bottom: -40px;
      left: 78px;
      .iconfont {
        font-size: 30px;
      }
    }
    .flow_icon_r,
    .flow_icon_l {
      margin-left: 40px;
      .iconfont {
        font-size: 30px;
      }
    }
  }
}
.core_ul {
  position: relative;
  height: 420px;
  .core_li {
    position: absolute;
    width: 350px;
    height: 380px;
    box-shadow: 0 0 10px 2px #dae5f3;
    background: #fff;
    border-radius: 6px;
    cursor: pointer;
    transform: translateX(-50%);
    animation: core_move_F 0.2s linear;
    animation-fill-mode: forwards;
  }
  .core_li_con {
    position: relative;
    text-align: center;
    padding: 20px;
    box-sizing: border-box;
    .core_img_icon {
      margin: 10px auto;
    }
    .core_img_icon,
    .core_img_icon_hover {
      img {
        width: 100%;
        height: 100%;
      }
      width: 80px;
      height: 80px;
    }
    .core_img_icon_hover {
      position: absolute;
      left: 50%;
      top: 0;
      transform: translate(-50%, -50%);
    }
    .core_title {
      font-size: 20px;
      font-weight: 700;
      margin-bottom: 10px;
    }
    .core_info {
      color: #333;
      margin-bottom: 15px;
    }
    .core_business {
      span {
        display: block;
        text-align: center;
        margin-bottom: 5px;
        font-size: 16px;
        color: #333;
      }
    }
  }
  .core_li_item0 {
    left: 13%;
    z-index: 1;
  }
  .core_li_item1 {
    left: 33%;
    z-index: 2;
  }
  .core_li_item2 {
    left: 52%;
    z-index: 3;
  }
  .core_li_item3 {
    left: 72%;
    z-index: 2;
  }
  .core_li_item4 {
    left: 92%;
    z-index: 1;
  }
  .core_li_hover_con {
    padding-top: 50px;
  }
  .core_li_hover {
    z-index: 11 !important;
    animation: core_move 0.2s linear;
    animation-fill-mode: forwards;
  }
  .core_hover_info {
    font-size: 12px;
    color: #666;
    margin-bottom: 30px;
    line-height: 24px;
  }
  .core_hover_btn {
    font-size: 12px;
    color: #666;
    line-height: 24px;
  }
}
.safe {
  display: flex;
  .safe_l{
    width: 300px;
    .safe_li{
      display: flex;
      align-items: center;
      padding: 10px;
      cursor: pointer;
      .safe_li_l{
        width: 70px;
        height: 70px;
        margin-right: 10px;
        img{
          display: block;
          width: 100%;
          height: 100%;
        }
      }
      .safe_li_r{
        flex: 1;
        color: #8994c6;
        .safe_li_tit{
          font-size: 20px;
          margin-bottom: 5px;
        }
        .safe_li_info{
          font-size: 12px;
        }
      }
    }
    .safe_li_hover{
      background: #f8faff;
      .safe_li_r{
        color: #30277f!important;
      }
    }
  }
  .safe_r{
    flex: 1;
    padding: 20px;
    box-sizing: border-box;
    background: #f8faff;
    position: relative;
    height: 460px;
    .safe_img{
      position: absolute;
      top: 20px;
      left: 20px;
      width: 850px;
      height: 460px;
      img{
        display: block;
        width: 100%;
      }
    }
  }
}
.simple{
  position: relative;
  .simple_ul{
    display: flex;
    flex-wrap: wrap;
    .simple_li{
      width: 50%;
      display: flex;
      margin-bottom: 20px;
      .simple_con{
        width: 40%;
        cursor: pointer;
      }
      .simple_title{
        color: #000;
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 12px;
      }
      .simple_info{
        color: #8c8c8c;
        font-size: 14px;
        line-height: 22px;
      }
      .acsimple_con{
        .simple_title{
          color: #3399FF;
        }
        
      }
    }
    .simple_li:nth-child(2n-1){
      text-align: left;
      justify-content: flex-start;
    }
    .simple_li:nth-child(2n){
      text-align: right;
      justify-content: flex-end;
    }
  }
  .simple_img{
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate(-50%,0);
    width: 700px;
    img{
      width: 100%;
    }
  }
}
</style> 
