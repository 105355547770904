<template>
  <div class="totch-nav">
    <div
      v-for="(item,index) in miniList"
      :key="index"
    >
      <div
        class="item"
        v-show="item.miniShow"
      >
        <div class="name">
          <img
            :src="item.content.icon"
            width="45"
            alt=""
          >
          <span class="mil">{{item.content.name}}</span>
        </div>
        <div class="line"> </div>
        <div class="desc">
          {{item.content.content}}
        </div>
        <div class="tags">

          <span
            v-for="(names,nameIndex) in item.content.tags"
            :key="nameIndex"
          >{{names}}</span>
        </div>
        <div class="detail">
          <a :href="item.content.href"><button class="seeBtn">查看详情</button></a>
        </div>
      </div>
      <div
        class="mini"
        style="border-left: 1px solid rgb(231, 230, 232);"
        v-show="!item.miniShow"
        @mouseenter="moMini(index)"
      >
        <div class="icons">
          <img
            :src="item.icon"
            width="35"
            alt=""
          >
        </div>
        <div class="name">{{item.name}}</div>
        <div class="line"></div>
        <div
          class="tags"
          v-for="(name,nameIndex) in item.tags"
          :key="nameIndex"
        >{{name}}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["miniLists"],
  data() {
    return {
      miniList: this.miniLists,
    };
  },
  watch: {
    miniLists(val) {
      this.miniList = val;
    },
  },
  methods: {
    // 合作服务
    moMini(val) {
      // console.log(val);
      if (val == 0) {
        this.miniList[0].miniShow = true;
        this.miniList[1].miniShow = false;
        this.miniList[2].miniShow = false;
        return;
      } else if (val == 1) {
        this.miniList[0].miniShow = false;
        this.miniList[1].miniShow = true;
        this.miniList[2].miniShow = false;
        return;
      } else if (val == 2) {
        this.miniList[0].miniShow = false;
        this.miniList[1].miniShow = false;
        this.miniList[2].miniShow = true;
        return;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.totch-nav {
   width: 100%;
            display: flex;
  .item {
    background: #fff;
    border: 1.5px solid #fdc3c7;
    margin-top: -2px;
    width: 560px;
    height: 300px;
    padding: 40px;
    box-shadow: 0 2px 10px 0 #eceaee;
    .name {
      color: #262626;
      display: flex;
      flex-direction: row;
      align-items: center;
      .mil {
        font-weight: 700;
        margin-left: 15px;
        font-size: 22px;
      }
    }
    .line {
      width: 100%;
      // height: 1px;
      background-color: hsla(0, 0%, 63.9%, 0.2);
      border: 1px solid hsla(0, 0%, 63.9%, 0.2);
      margin-bottom: 30px;
      margin-top: 30px;
    }
    .desc {
      flex-basis: 66px;
      color: #666;
      font-size: 16px;
      line-height: 27px;
      margin-bottom: 15px;
    }
    .tags {
      font-size: 14px;
      display: flex;
      flex-direction: row;
      span {
        color: #EB2A38;
        display: inline-block;
        margin-right: 10px;
        padding: 7px 16px;
        text-align: center;
        background: rgba(247, 66, 78, 0.12);
        border-radius: 14px;
      }
    }
    .detail {
      margin-top: 24px;
      a {
        .seeBtn {
          width: 134px;
          height: 42px;
          border-radius: 2px;
          border: 1px solid #ffa4ab;
          // color: #4827b6;
                          color: #fd4854;

          align-items: center;
          background-color: transparent;
        }
      }
    }
  }
  .mini {
    width: 284px;
    height: 379px;
    background: #fff;
    border-top: 1px solid #e7e6e8;
    border-right: 1px solid #e7e6e8;
    border-bottom: 1px solid #e7e6e8;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .name {
      color: #262626;
      margin-top: 18px;
      font-weight: 700;
      font-size: 18px;
    }
    .line {
      width: 52px;
      height: 1px;
      opacity: 0.2;
      border: 1px solid #a3a3a3;
      background-color: #a3a3a3;
      margin: 30px 0;
    }
    .tags {
      font-size: 14px;
      margin-bottom: 24px;
      color: #666;
    }
  }
}
</style>