<template>
  <div class="list">
    <div
      class="item"
      v-for="(item,index) in priceList"
      :key="index"
    >
      <div
        class="name"
        :style="item.bags==0?'background-color: #fee8ea;':(item.bags==1?'background:linear-gradient(120deg, rgb(237, 37, 52) 0%, rgb(202, 152, 156) 100%);':(item.bags==2?'background: linear-gradient(140deg, rgb(236, 40, 55) 43%, rgb(203, 148, 153) 100%);':'background-color: #E3EAFC;'))"
      >
        <span
          class="titles"
          :style="item.bags==0?'color:#081631;':(item.bags==1?'color:#fff;':(item.bags==2?'color:#fff;':'color:#081631'))"
        >{{item.title}}</span>
        <span
          class="fu"
          :style="item.bags==0?'color:#081631;':(item.bags==1?'color:#fff;':(item.bags==2?'color:#fff;':'color:#081631'))"
        >{{item.fu}}</span>
        <span
          class="fus"
          :style="item.bags==0?'color:#081631;':(item.bags==1?'color:#fff;':(item.bags==2?'color:#fff;':'color:#081631'))"
        >{{item.fus}}</span>
      </div>
      <div class="prices-type">
        <div class="types">
          <div>
            <span>￥</span>
            <span>{{item.price}}</span>
          </div>
        </div>
      </div>
      <el-button
        class="btn"
        @click="onPurchase(item.bags)"
        :disabled="item.bags==0?true:(item.bags==1?false:(item.bags==2?false:'true'))"
        :style="item.bags==0?'color:#081631;':(item.bags==1?'border:1px solid #ffa4ab;color:#fd4854;':(item.bags==2?'backgroundColor:#fd4854;color:#fff;':''))"
      >
        {{item.bags==0?'永久免费':(item.bags==1?'立即购买':(item.bags==2?'立即购买':'永久免费'))}}
      </el-button>
      <div class="contents">
        <div class="title-con">
          <div class="titless">团队协作</div>
          <div class="con-item">
            <span>团队人数</span>
            <span>{{item.teamNum}}人</span>
          </div>
          <div class="con-item">
            <span>账号管理</span>
            <span>{{item.accountNum}}</span>
          </div>
          <div class="con-item">
            <span>账号中心打开标签数</span>
            <span>{{item.labelNum}}</span>
          </div>
          <div class="con-item">
            <span :style="item.earnings?'rgb(27, 27, 27)':'color:#ccc'">屏蔽账号收益</span>
            <i
              class="el-icon-check"
              style="color:#fd4854;"
              v-if="item.earnings"
            ></i>
            <i
              class="el-icon-close"
              style="color:#ccc;"
              v-else
            ></i>
          </div>
        </div>
        <div class="title-con">
          <div class="titless">发布功能</div>
          <div class="con-item">
            <span>分发速度-图文</span>
            <span>{{item.issueSpeed}}账号/分发</span>
          </div>
          <div class="con-item">
            <span>图文/视频分发</span>
            <span>{{item.issueImg}}</span>
          </div>
          <div class="con-item">
            <span>多任务一键发布</span>
            <span>{{item.issueTask}}</span>
          </div>
          <div class="con-item">
            <span :style="item.issueDraft?'rgb(27, 27, 27)':'color:#ccc'">同步至平台草稿箱</span>
            <i
              class="el-icon-check"
              style="color:#fd4854;"
              v-if="item.issueDraft"
            ></i>
            <i
              class="el-icon-close"
              style="color:#ccc;"
              v-else
            ></i>
          </div>
          <div class="con-item">
            <span :style="item.issueTitle?'rgb(27, 27, 27)':'color:#ccc'">标题助手</span>
            <i
              class="el-icon-check"
              style="color:#fd4854;"
              v-if="item.issueTitle"
            ></i>
            <i
              class="el-icon-close"
              style="color:#ccc;"
              v-else
            ></i>
          </div>
          <div class="con-item">
            <span :style="item.issueHotspot?'rgb(27, 27, 27)':'color:#ccc'">热点发现</span>
            <i
              class="el-icon-check"
              style="color:#fd4854;"
              v-if="item.issueHotspot"
            ></i>
            <i
              class="el-icon-close"
              style="color:#ccc;"
              v-else
            ></i>
          </div>
          <div class="con-item">
            <span :style="item.issueOnLine?'rgb(27, 27, 27)':'color:#ccc'">在线搜图</span>
            <i
              class="el-icon-check"
              style="color:#fd4854;"
              v-if="item.issueOnLine"
            ></i>
            <i
              class="el-icon-close"
              style="color:#ccc;"
              v-else
            ></i>
          </div>
          <div class="con-item">
            <span>质量检测</span>
            <span>{{item.issueDetection}}</span>
          </div>
          <div class="con-item">
            <span :style="item.issueGrouping?'rgb(27, 27, 27)':'color:#ccc'">账号分组</span>
            <i
              class="el-icon-check"
              style="color:#fd4854;"
              v-if="item.issueGrouping"
            ></i>
            <i
              class="el-icon-close"
              style="color:#ccc;"
              v-else
            ></i>
          </div>
          <div class="con-item">
            <span :style="item.issueTheHeavy?'rgb(27, 27, 27)':'color:#ccc'">防重复发布机制</span>
            <i
              class="el-icon-check"
              style="color:#fd4854;"
              v-if="item.issueTheHeavy"
            ></i>
            <i
              class="el-icon-close"
              style="color:#ccc;"
              v-else
            ></i>
          </div>
          <div class="con-item">
            <span :style="item.issueLabel?'rgb(27, 27, 27)':'color:#ccc'">各平台独立标签</span>
            <i
              class="el-icon-check"
              style="color:#fd4854;"
              v-if="item.issueLabel"
            ></i>
            <i
              class="el-icon-close"
              style="color:#ccc;"
              v-else
            ></i>
          </div>
          <div class="con-item">
            <span :style="item.issueTitles?'rgb(27, 27, 27)':'color:#ccc'">各平台独立标题</span>
            <i
              class="el-icon-check"
              style="color:#fd4854;"
              v-if="item.issueTitles"
            ></i>
            <i
              class="el-icon-close"
              style="color:#ccc;"
              v-else
            ></i>
          </div>
          <div class="con-item">
            <span :style="item.issueTiming?'rgb(27, 27, 27)':'color:#ccc'">定时发布</span>
            <i
              class="el-icon-check"
              style="color:#fd4854;"
              v-if="item.issueTiming"
            ></i>
            <i
              class="el-icon-close"
              style="color:#ccc;"
              v-else
            ></i>
          </div>
          <div class="con-item">
            <span :style="item.issueOriginal?'rgb(27, 27, 27)':'color:#ccc'">声明原创/首发</span>
            <i
              class="el-icon-check"
              style="color:#fd4854;"
              v-if="item.issueOriginal"
            ></i>
            <i
              class="el-icon-close"
              style="color:#ccc;"
              v-else
            ></i>
          </div>
          <div class="con-item">
            <span :style="item.issueRecordImg?'rgb(27, 27, 27)':'color:#ccc'">记录图文原创</span>
            <i
              class="el-icon-check"
              style="color:#fd4854;"
              v-if="item.issueRecordImg"
            ></i>
            <i
              class="el-icon-close"
              style="color:#ccc;"
              v-else
            ></i>
          </div>
          <div class="con-item">
            <span :style="item.issueRecordvideo?'rgb(27, 27, 27)':'color:#ccc'">记录视频原创</span>
            <i
              class="el-icon-check"
              style="color:#fd4854;"
              v-if="item.issueRecordvideo"
            ></i>
            <i
              class="el-icon-close"
              style="color:#ccc;"
              v-else
            ></i>
          </div>
        </div>
        <div class="title-con">
          <div class="titless">数据统计</div>
          <div class="con-item">
            <span :style="item.dataPlatform?'rgb(27, 27, 27)':'color:#ccc'">平台数据</span>
            <i
              class="el-icon-check"
              style="color:#fd4854;"
              v-if="item.dataPlatform"
            ></i>
            <i
              class="el-icon-close"
              style="color:#ccc;"
              v-else
            ></i>
          </div>
          <div class="con-item">
            <span :style="item.dataStaff?'rgb(27, 27, 27)':'color:#ccc'">员工数据</span>
            <i
              class="el-icon-check"
              style="color:#fd4854;"
              v-if="item.dataStaff"
            ></i>
            <i
              class="el-icon-close"
              style="color:#ccc;"
              v-else
            ></i>
          </div>
          <div class="con-item">
            <span :style="item.dataAccount?'rgb(27, 27, 27)':'color:#ccc'">账号数据</span>
            <i
              class="el-icon-check"
              style="color:#fd4854;"
              v-if="item.dataAccount"
            ></i>
            <i
              class="el-icon-close"
              style="color:#ccc;"
              v-else
            ></i>
          </div>
          <div class="con-item">
            <span :style="item.dataContent?'rgb(27, 27, 27)':'color:#ccc'">内容统计</span>
            <i
              class="el-icon-check"
              style="color:#fd4854;"
              v-if="item.dataContent"
            ></i>
            <i
              class="el-icon-close"
              style="color:#ccc;"
              v-else
            ></i>
          </div>
        </div>
      </div>
      <div class="xvqiu">
        <span :style="item.satisfy?'rgb(27, 27, 27)':'color:#ccc'">需求优先满足</span>
        <i
          class="el-icon-check"
          style="color:#fd4854;"
          v-if="item.satisfy"
        ></i>
        <i
          class="el-icon-close"
          style="color:#ccc;"
          v-else
        ></i>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["priceLists"],
  data() {
    return {
      priceList: this.priceLists,
    };
  },
  watch: {
    priceLists(val) {
      this.priceList = val;
    },
  },
  methods: {
    //立即购买
    // 1代表月费
    // 2代码年费
    onPurchase(val) {
        
      this.$emit("onPurchases", val);
    //   console.log(val)
    },
  },
};
</script>

<style lang="less" scoped>
.list {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  height: 1380px;
  margin: 100px 0;
  .item {
    width: 25%;
    background-color: rgb(255, 255, 255);
    border-radius: 10px;
    border: #cccccc solid 1px;
    position: relative;
    .name {
      display: flex;
      flex-direction: column;
      text-align: center;
      padding: 40px 0;
      border-radius: 10px 10px 0 0;
      box-sizing: border-box;
      height: 141px;
      .titles {
        color: #081631;
        font-weight: 700;
        font-size: 20px;
        margin-bottom: 20px;
      }
      .fu {
        color: rgba(38, 38, 38, 0.85);
      }
      .fus {
        padding: 10px 0;
        color: rgba(38, 38, 38, 0.85);
      }
    }
    .prices-type {
      height: 100px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .types {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        div {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          span:nth-child(1) {
            font-size: 18px;
            font-weight: 700;
            color: #081631;
          }
          span:nth-child(2) {
            font-size: 30px;
            font-weight: 700;
            color: #081631;
          }
        }

        .re {
          font-size: 14px;
          color: #a1a1a1;
          padding-bottom: 5px;
          box-sizing: border-box;
        }
      }
    }
    .btn {
      display: flex;
      flex-direction: row;
      justify-content: center;
      width: 120px;
      margin: 40px auto 20px auto;
      a {
        color: #02050c;
      }
    }
    .contents {
      .title-con {
        padding: 18px 24px;
        .titless {
          box-sizing: border-box;
          font-size: 15px;
          font-weight: 700;
          color: #262626;
          text-align: center;
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          margin-bottom: 20px;
          &::after {
            content: "";
            background-image: linear-gradient(
              90deg,
              rgba(8, 8, 8, 0) 10%,
              #535353 10%,
              #9e9e9e 10%,
              rgba(121, 121, 121, 0) 86%
            );
            width: 80px;
            height: 1px;
          }
          &::before {
            content: "";
            background-image: linear-gradient(
              -90deg,
              rgba(8, 8, 8, 0) 10%,
              #535353 10%,
              #9e9e9e 10%,
              rgba(121, 121, 121, 0) 86%
            );
            width: 80px;
            height: 1px;
          }
        }
        .con-item {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          span {
            padding: 8px 0;
            font-size: 12px;
            color: rgb(27, 27, 27);
          }
          span:nth-child(2) {
            font-size: 12px;
            color: #fd4854;
          }
        }
      }
    }
    .xvqiu {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 0 24px;
      position: absolute;
      box-sizing: border-box;
      bottom: 20px;
      width: 100%;
      span {
        padding: 8px 0;
        font-size: 12px;
        color: rgb(31, 31, 31);
      }
      span:nth-child(2) {
        font-size: 12px;
        color: #0180ff;
      }
    }
  }
}
</style>