<template>
  <div id="app">
   <router-view/>
  </div>
</template>

<script>

export default {
  name: 'app',
}
</script>

<style>
@import './common/css/icon.css';
@import "./style/public.css";
.el-carousel--horizontal,.el-carousel__container{
  height: 100%!important;
}
</style>
