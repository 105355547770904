<template>
  <!-- 内容运营 -->
  <div class="app-foote">
    <div
      class="footer"
      v-if="false"
    >
      <div class="widths">

        <p class="title">
          真琦—让内容运营更高效
        </p>
        <div class="mt">开启高效运营时代</div>
        <!-- <el-button class="btn">免费下载使用</el-button> -->
        <div class="jsb flex">
          <img
            src="../assets/home/icon/科大讯飞.888ab04.png"
            alt=""
            height="43"
          >
          <img
            src="../assets/home/icon/中国搜索.0e26089.png"
            alt=""
            height="43"
          >
          <img
            src="../assets/home/icon/开普云.d7d1289.png"
            alt=""
            height="43"
          >
          <img
            src="../assets/home/icon/两面针.cdc8122.png"
            alt=""
            height="43"
          >
        </div>
      </div>
    </div>
    <div class="wrap">
      <div class="widths">
        <div class="line"></div>
        <div class="funt flex">
          <div class="fc flex">
            <img
              src="../assets/home/yiyuan_home40.png"
              width="42"
              alt=""
            >
            <span class="mt">7*24小时运维服务</span>
          </div>
          <div class="fc flex">
            <img
              src="../assets/home/yiyuan_home41.png"
              width="42"
              alt=""
            >
            <span class="mt">高频度系统安全更新</span>
          </div>
          <div class="fc flex">
            <img
              src="../assets/home/yiyuan_home42.png"
              width="42"
              alt=""
            >
            <span class="mt">域名备案、营销指导</span>
          </div>
          <div class="fc flex">
            <img
              src="../assets/home/yiyuan_home43.png"
              width="42"
              alt=""
            >
            <span class="mt">主动回访了解客户需求</span>
          </div>

        </div>
        <div class="line"></div>
        <div class="content flex">
          <div class="logo">
            <img
              src="../assets/logo839.png"
              alt=""
              width="108"
            >
          </div>
          <div class="autos">
            <span>真琦科技</span>
            <a
              href=""
              @click="goTousre('/userDemo')"
              style="margin-bottom:10px;"
            >
              <span>用户案例</span>
            </a>
            <a
              href=""
              @click="goTousre('/technology')"
              style="margin-bottom:10px;"
            >
              <span>技术介绍</span>
            </a>
            <a
              href=""
              @click="goTousre('/about')"
            >
              <span>联系我们</span>
            </a>
            <div class="mts">
              <span>客服电话</span>
              <span>15356103840</span>
            </div>
          </div>
          <div class="autos lianjie">
            <span>友情链接</span>
            <a
              href="http://www.gcjlkj.com"
              target="_blank"
            >
              <span>真琦科技</span>
            </a>
          </div>
          <div class="contact">
            <span class="wi">关注公众号</span>
            <img
              src="../assets/home/客服微信.png"
              alt=""
              width="114"
              height="114"
              title="真琦科技"
            >
          </div>
          <div class="contact">
            <span class="wi">添加客服微信</span>
            <img
              src="../assets/home/客服微信.png"
              alt=""
              width="114"
              height="114"
              title="真琦科技"
            >
          </div>
        </div>
        <div class="company">
          <a
            href="http://www.gcjlkj.com"
            target="_blank"
          >杭州真琦科技有限公司 </a>
          <span> 版权所有 Copyright © 2021. All rights reserved </span>
          <a
            href="https://beian.miit.gov.cn/"
            target="_blank"
          >浙ICP备19019932号 </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  mounted() {
    setTimeout(() => {
      let one = document.querySelector("#one").offsetTop;
      let two = document.querySelector("#two").offsetTop;
      let there = document.querySelector("#there").offsetTop;
      let fore = document.querySelector("#fore").offsetTop;
      let five = document.querySelector("#five").offsetTop;
      let six = document.querySelector("#six").offsetTop;
      let serven = document.querySelector("#serven").offsetTop;
      let event = document.querySelector("#event").offsetTop;
      let nice = document.querySelector("#nice").offsetTop;
      window.addEventListener("scroll", (e) => {
        if (document.documentElement.scrollTop > nice) {
          this.scrollIndex = 8;
        } else if (document.documentElement.scrollTop + 20 > event) {
          this.scrollIndex = 7;
        } else if (document.documentElement.scrollTop + 20 > serven) {
          this.scrollIndex = 6;
        } else if (document.documentElement.scrollTop + 20 > six) {
          this.scrollIndex = 5;
        } else if (document.documentElement.scrollTop + 20 > five) {
          this.scrollIndex = 4;
        } else if (document.documentElement.scrollTop + 20 > fore) {
          this.scrollIndex = 3;
        } else if (document.documentElement.scrollTop + 20 > there) {
          this.scrollIndex = 2;
        } else if (document.documentElement.scrollTop + 20 > two) {
          this.scrollIndex = 1;
        } else {
          this.scrollIndex = 0;
        }
      });
    }, 1000);

    // this.simpleTime = setInterval(()=>{
    //   if(this.simpleIndex == 9){
    //     this.simpleIndex = 0
    //   }
    //   this.simpleIndex++
    // },3000)
  },
  destroyed() {
    clearInterval(this.simpleTime);
  },
  methods: {
    openUrl(url) {
      window.open(url);
    },
    goTousre(url = "/userDemo") {
      this.$router.push(url);
    },
  },
};
</script>
<style lang="less" scoped>
@import url("../style/Home.less");
</style>